import { flow } from 'lodash'
import { AppState } from '../../../store'

export const insertUpdateSPAState = (state: AppState) =>
  state.modules.SPA.advisor?.insertUpdateSPAState
export const getClientSearchState = (state: AppState) =>
  state.modules.SPA.advisor?.clientSearchState

export const getSecuritySearchState = (state: AppState) =>
  state.modules.SPA.advisor?.securitySearchState

export const getAccountSearchState = (state: AppState) =>
  state.modules.SPA.advisor?.accountSearchState

export const getErrorAccountSearchState = (state: AppState) =>
  state.modules.SPA.advisor?.errorSearchState

export const getExistingSPAState = (state: AppState) =>
  state.modules.SPA.advisor?.fetchExistingSPAState

export const getAdvisorContextState = (state: AppState) => state.context.advisor

export const updateSPAStatusState = (state: AppState) =>
  state.modules.SPA.advisor?.updateSPAState

export const getSPAAttachmentsState = (state: AppState) =>
  state.modules.SPA.advisor?.spaAttachmentsState

export const getDownloaddSPADocState = (state: AppState) =>
  state.modules.SPA.advisor?.SPADownload
export const deleteSPAAttachmentsState = (state: AppState) =>
  state.modules.SPA.advisor?.SPADelete
export const insertSPAAttachmentsState = (state: AppState) =>
  state.modules.SPA.advisor?.insertAttachmentsSPAState
export const generatePDFState = (state: AppState) =>
  state.modules.SPA.advisor?.generatePDFState
export const CustodianNameState = (state: AppState) =>
  state.modules.SPA.advisor?.fetchCustodianNameState
export const validateSPAState = (state: AppState) =>
  state.modules.SPA.advisor?.validateSPAState
export const errorSPAState = (state: AppState) =>
  state.modules.SPA.advisor?.errorSPAState
export const generateAccountNumberState = (state: AppState) =>
  state.modules.SPA.advisor?.generateAccountNumberState
export const getCusipSequenceNumberState = (state: AppState) =>
  state.modules.SPA.advisor?.fetchCusipSequenceNumberState
export const getAdvisorSecurityByCusipState = (state: AppState) =>
  state.modules.SPA.advisor?.fetchAdvisorSecurityByCusipState
export const getAdvisorAccountsByClientIdState = (state: AppState) =>
  state.modules.SPA.advisor?.fetchAdvisorAccountsByClientIdState
export const getExistedAccountNumberState = (state: AppState) =>
  state.modules.SPA.advisor?.existedAccountNumberState
export const getFundAdminsState = (state: AppState) =>
  state.modules.SPA.advisor?.fetchFundAdminsState

export const SPAAdvisorState = (state: AppState) => state.modules.SPA.advisor
export const isSPAInProgress = flow(
  SPAAdvisorState,
  (x) =>
    x?.insertUpdateSPAState?.inProgress ||
    x?.fetchExistingSPAState?.inProgress ||
    x?.clientSearchState?.inProgress ||
    x?.securitySearchState?.inProgress ||
    x?.updateSPAState?.inProgress ||
    x?.insertAttachmentsSPAState?.inProgress ||
    x?.accountSearchState?.inProgress ||
    x?.spaAttachmentsState?.inProgress ||
    x?.SPADownload?.inProgress ||
    x?.SPADelete?.inProgress ||
    x?.fetchCustodianNameState?.inProgress ||
    x?.validateSPAState?.inProgress ||
    x?.errorSearchState?.inProgress
)
